.input-box-container
{
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    flex-flow: column;
    gap:10px;
    justify-content:flex-start;
    align-items: center;
}
.input-box{
    width: 100%;
    height: 35px;
    border: 3px solid #f7d47e;
    border-radius: 5px;
    font-size: 18px;
    letter-spacing: 0.1em;
    font-weight: 500;
}

.input-box:focus{
    outline: none;
}

.input-box-label{
    font-size: 16px;
    width: 20%;
    text-transform: uppercase;
    font-weight: 100;
    color:#f7d47e;
    margin-right: auto;
    width: 100%;
    text-align: left;
}

@media (min-width:1060px) {
    .input-box-container
    {
        width: 50%;
        gap:30px;
        flex-direction: row;
        flex-flow: row;
        gap:10px;
        justify-content: space-between;
        align-items: center;
    }

    .input-box-label{
        font-size: 20px;
        width: fit-content;
        text-align: center;
    }

    .input-box{
        width: 70%;
    }
}