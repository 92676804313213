.about-us {
  width: 100%;
}

.about-us-details {
  width: 100%;
  display: flex;
  flex-flow: column;
  flex-direction: column;
  margin: 0 auto;
  gap: 20px;
}

.about-us-title {
  justify-content: center;
  text-align: center;
  color: #b39557;
  font-weight: 900;
  text-transform: uppercase;
}

.about-us-description {
  max-width: 80%;
  text-align: justify;
  margin: 0 auto;
  color: #b39557;
  font-size: 13px;
  font-weight: 100;
}

.our-team {
  margin: 0 auto;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
}

.team-title {
  color: #b39557;
  text-transform: uppercase;
}

.team-members{
  display: flex;
  flex-direction: column;
  flex-direction: column;
  align-items: flex-start;
  gap: 50px;
}

@media (min-width: 1110px) {
  .about-us-details {
    width: 50%;
  }

  .about-us-description {
    font-size: 17px;
  }

  .our-team {
    width: 95%;
    justify-content: space-evenly;
  }

  .team-members{
    flex-direction: row;
    flex-direction: row;
  }
}
