.navbar{
  width: 100%;
  height: fit-content;
  background-color: #1f2022;
  border-top: solid 1px #f7d47e;
  border-bottom: solid 1px #f7d47e;
  position: fixed;
  z-index:1000;
}
.navbar-container {
  width: 90%;
  height: 70px;
  display: flex;
  flex-flow: row;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: auto;
}

.hamburger-react{
  display: block;
}

@media (min-width : 1060px) {
  .navbar-container{
    justify-content: space-evenly;
    width: 100%;
  } 

  .hamburger-react{
    display: none;
  }
  .navbar-container {
    height: 120px;
  }
}