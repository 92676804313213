.navbar-item {
  color: #b39557;
  font-weight: 400;
  letter-spacing: 0.15em;
  text-decoration: none;
  cursor: pointer;
}

.navbar-item:hover {
  color: #f1d4a0;
}

.chalk-underline {
  position: relative; /* Positioning context for the pseudo-element */
  display: inline-block; /* Ensures the underline is applied to the text */
  transition: opacity 0.3s ease; /* Smooth transition */
}

.chalk-underline:hover::after {
  content: ""; /* Pseudo-element content */
  position: absolute; /* Position it absolutely within the parent */
  left: 0; /* Align with the left edge */
  right: 0; /* Align with the right edge */
  bottom: -5px; /* Position below the text */
  height: 2px; /* Thickness of the underline */
  background: #f1d4a0; /* Color of the underline */
  box-shadow: 0 0 5px #f1d4a0, /* Light chalk effect */ 0 0 10px #f1d4a0; /* Softer outer glow */
  filter: blur(1px); /* Optional: add blur for a softer look */
}
