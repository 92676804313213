.social-media-icon {
    color: #b39557;
    font-weight: 400;
    letter-spacing: 0.15em;
    text-decoration: none;
    cursor: pointer;
    font-size:1.7em;
  }
  
  .social-media-icon:hover {
    color: #f1d4a0;
  }
  