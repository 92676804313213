.team-member-content {
  display: flex;
  flex-flow: column;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
}

.team-member-content img {
  width: 150px;
  height: auto;
  border-radius: 50%;
  border: solid 5px #b39557;
}

.team-member-details {
  display: flex;
  flex-direction: column;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
}

.team-member-name {
  font-size: 20px;
  text-transform: uppercase;
  color: #b39557;
  font-weight: 900;
}

.team-member-description {
  font-weight: 100;
  font-size: 13px;
  max-width: 90%;
  color: #b39557;
  text-align: justify;
}

@media (min-width: 1110px) {
  .team-member-content {
    flex-flow: row;
    flex-direction: row;
  }

  .team-member-content img {
    width: 250px;
  }

  .team-member-details {
    justify-content: flex-start;
    align-items: flex-start;
  }

  .team-member-description {
    font-size: 17px;
    max-width: 100%;
  }
}
