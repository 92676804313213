.dropdown-item{
    text-decoration: none;
    color : #b39557;
    font-weight: 400;
    cursor: pointer;
    padding: 10px;
    border-top: solid 0.5px rgba(247, 212, 126, 0.1);
    border-bottom: solid 0.5px rgba(247, 212, 126, 0.1);
    letter-spacing: 0.15em;
}

.dropdown-item:hover {
    color: #f1d4a0;
  }