.social-media-icons
{
    padding: 0 10px;
    display: none;
    flex-direction: row;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    gap:20px;
}

@media (min-width:1060px) {
    .social-media-icons{
        display: flex;
    }
}