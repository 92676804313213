.button {
  appearance: none;
  position: relative;
  display: inline-block;
  min-width: 2.59815rem;
  width: 20rem;
  height: 3rem;
  margin: auto;
  padding: 0.5rem 0;
  text-align: center;
  font-size: 1rem;
  line-height: 1rem;
  vertical-align: middle;
  color: #ffffff;
  cursor: pointer;
  border: none;
  background-color: #b39557;
  clip-path: polygon(
    0.8660008083rem 0,
    calc(100% - 0.8660008083rem) 0,
    100% 50%,
    calc(100% - 0.8660008083rem) 100%,
    0.8660008083rem 100%,
    0 50%
  );
  font-weight: 900;
  letter-spacing: 0.15em;
}
.button::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #b39557;
  clip-path: polygon(
    0 50%,
    2px 50%,
    calc(0.8660008083rem + 1px) 2px,
    calc(100% - 0.8660008083rem - 1px) 2px,
    calc(100% - 2px) 50%,
    calc(100% - 0.8660008083rem - 1px) calc(100% - 2px),
    calc(0.8660008083rem + 1px) calc(100% - 2px),
    2px 50%,
    0 50%,
    0 100%,
    100% 100%,
    100% 50%,
    100% 0,
    0 0
  );
}
.button:hover {
  color: #123143;
  background-color: #ffce00;
}
