.dropdown-social-media-icons{

    display: flex;
    flex-direction: row;
    flex-flow: row;
    gap : 20px;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
}
