.logo {
  display: flex;
  flex-flow: row;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.logo-title {
  color: #af8f4f;
  display: flex;
  flex-direction: column;
  flex-flow: column;
  height: 100%;
  margin: 0;
  padding: 0;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  font-size: 12px;
}

.logo-initial {
  color: #f1d4a0;
}

.avyaan-logo {
  width: 40px;
  height: auto;
}

@media (min-width: 1060px) {
  .logo-title {
    font-size: 16px;
  }
}
