.title{
    margin: 50px auto 0 auto;
    text-transform: uppercase;
    font-size:22px;
    color:#b39557;
}

@media (min-width:1060px) {
    .title{
        font-size: 40px;
    }
}