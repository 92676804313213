.navbar-items {
  display: none;
  flex-flow: row;
  flex-direction: row;
  gap: 40px;
}

@media (min-width : 1060px) {
  .navbar-items{
    display: flex;
  } 
}
