.container-title {
  color: #b39557;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 900;
  max-width: 90%;
  text-align: center;
  padding: 0;
  margin: auto;
  transform: translateY(50px);
}

@media (min-width:1060px)
{
  .container-title {
    font-size: 40px;
    max-width: 600px;
    text-align: left;
    padding: 0 15%;
    transform: translateY(0);
    margin: 0;
  }
}
