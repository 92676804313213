.dropdown-navbar{
    width: 100%;
    padding: 20px 0;
    z-index : 1000;
    position : fixed;
    top : 70px;
    left: 0;
    background-color: #1f2022;
    border-top: solid 1px #f7d47e;
    display: block;
    transform-origin: top;
}

.hide{
    /* transform: scaleY(0); */
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease-in;
}

.show{
    /* transform: scaleY(1); */
    opacity: 1;
    visibility: visible;
    transition: all 0.2s ease-in;
}

@media (min-width:1060px) {
    .dropdown-navbar{
        display: none;
    }
    
}

